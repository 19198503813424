import React, { useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Leaf from '../../components/GreenScore/Leaf';

const Message = ({
  message, date, sandboxMode, extraInfo, leaf,
}) => {
  useEffect(() => {
    // Force HeyForm to re-scan for forms after component mount
    if (window.HeyForm) {
      // Trigger the library's initialization
      const event = new Event('DOMContentLoaded');
      window.dispatchEvent(event);
    }
  }, []);

  return (
    <div className={`checkout-success ${leaf ?? 'common-wrapper'}`}>
      {leaf && (
        <section className="big-leaf">
          <h2>{window.i18('ALL_WENT_WELL')}</h2>
          <Leaf completion={1} />
        </section>
      )}
      <p className="common-small-font">{message}</p>
      {date && (
        <>
          <p className="common-small-font">
            {window.i18('YOU_HAVE_UP_TO')}
            <strong>
              {moment(date).format(`DD/MM/YY ${window.i18('AT')} HH:mm:ss`)}
            </strong>
            {' '}
            {window.i18('TO_VALIDATE_THIS_OPTION')}
          </p>
          <p className="common-small-font">
            {window.i18(
              'FARE_CONDITIONS_CAN_CHANGE',
            )}
          </p>
        </>
      )}
      {!sandboxMode && (
        <Link className="button-main" to="/account/tickets/">
          {window.i18('SEE_TICKETS')}
        </Link>
      )}
      {extraInfo && (
        <div
          className="checkout-success__extra-info info"
          dangerouslySetInnerHTML={{ __html: extraInfo }}
        />
      )}
      {process.env.REACT_APP_SURVEY_ID && (
        <>
          <div
            data-heyform-id={process.env.REACT_APP_SURVEY_ID}
            data-heyform-type="modal"
            data-heyform-custom-url="https://feedback.thetreep.com/form/"
            data-heyform-size="large"
            data-heyform-open-trigger="click"
            data-heyform-open-delay="5"
            data-heyform-open-scroll-percent="30"
            data-heyform-trigger-background="#1d4ed8"
            data-heyform-trigger-text="Donner mon avis"
            data-heyform-hide-after-submit="true"
            data-heyform-auto-close="3"
            data-heyform-transparent-background="false"
          />
          <button
            className="heyform__trigger-button"
            type="button"
            onClick={() => window.HeyForm.openModal(
              process.env.REACT_APP_SURVEY_ID,
            )}
          >
            Laisser mon avis
          </button>
        </>
      )}
    </div>
  );
};

const Success = ({
  message, status, date, extraInfo, sandboxMode,
}) => (
  <Message
    message={message}
    status={status}
    date={date}
    extraInfo={extraInfo}
    sandboxMode={sandboxMode}
    leaf
  />
);

export default Success;
